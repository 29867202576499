import React, { useEffect, useState } from 'react'
import ChevronIcon from '../../../components/icons/ChevronIcon';
import Checkbox from '../../../components/common/form/checkbox/Checkbox';
import { useCompaniesContext } from '../../../contexts/Companies';
import { apiClient } from "../../../libs/api/apiClient";
import BaseButton from "../../../components/common/Button/BaseButton";
import { showSuccessToast } from "../../../libs/helpers/toasts";
import Spoiler from "../../../components/v2/Spoiler";
import InputWrapper from "../../../components/v2/form/input/common/InputWrapper";
import { diff } from "../../../libs/helpers/objects";
import Input from "../../../components/v2/form/input/Input";
import TokenInput from "../../../components/v2/form/input/TokenInput";
import SingleSelect, { Option } from "../../../components/v2/form/input/select/SingleSelect";

// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { dialogModal } from "../../../components/common/Modal/DialogModal";

interface SBISPresto {
    id?: undefined | string;
    secretKey?: undefined | string;
    serviceKey?: undefined | string;
    pointId?: undefined | string;
    priceListId?: undefined | string;
    maxBonusDiscount?: undefined | number;
    isCouponNeedSend?: boolean;
    usePoints?: boolean;
    usePromo?: boolean;
    webhook?: string;
    integrationIdentifierSecret?: string | undefined

    payments?: {
        cash?: undefined | string;
        'on-delivery'?: undefined | string;
        online?: undefined | string;
        transfer?: undefined | string;
        loyaltyCard?: undefined | string;
    },
    statuses?: {
        '10'?: undefined | string;
        '11'?: undefined | string;
        '21'?: undefined | string;
        '70'?: undefined | string;
        '81'?: undefined | string;
        '90'?: undefined | string;
        '91'?: undefined | string;
        '100'?: undefined | string;
        '140'?: undefined | string;
        '200'?: undefined | string;
        '220'?: undefined | string;
    },
    salesChannel?: {
        vk?: string,
        site?: string,
        ios?: string,
        android?: string,
    }
    options?: Options
}

interface Options {
    pointId: Array<Option<string>>;
    priceListId: Array<Option<string>>;
}

type UpdateField = (name: string, value: any) => void;
type UpdateProperties = () => void;

interface Props {
    data: {
        id: bigint
        integrations: {
            sbisPresto?: SBISPresto | undefined
        }
    },
    updateField: UpdateField,
    errors: {[k in string]: string}
}

const fetchOptions = async (companyId: bigint, branchId: bigint, options: SBISPresto): Promise<Options> => {
    try {
        const response = await apiClient.branches.integrations.sbisPresto.options(companyId, branchId, { body: { options } });

        if (! response) {
            return {} as Options
        }

        if ("string" === typeof response) {
            return {} as Options
        }

        const { data } = response as any;

        return data;
    } catch (e) {
    }

    return {} as Options;
}

const showOptionsUpdatedToast = () => {
    showSuccessToast({
        content: 'Варианты опций успешно обновлены!'
    });
}

const payments: Option<string>[] = [
    {
        value: "card",
        label: "Оплата картой"
    },
    {
        value: "cash",
        label: "Оплата наличными"
    },
    {
        value: "online",
        label: "Оплата онлайн"
    },
];

const isDirty = (previous: SBISPresto, current: SBISPresto): boolean => {
    return 0 < Object.keys(diff<SBISPresto>(previous, current)).length
}

export default function SBISPresto({data, updateField, errors}: Props) {
    const {company} = useCompaniesContext();
    const [statuses, setStatuses] = useState([]);
    const [properties, setProperties] = useState<SBISPresto>(data.integrations?.sbisPresto || {});
    const [initialProperties, _] = useState<SBISPresto>(properties)
    const [options, setOptions] = useState<Options>(data.integrations.sbisPresto?.options || {} as Options);
    const [loadSecretToken, setLoadSecretToken] = useState(false);

    useEffect(() => {
        if (company?.statuses) {
            setStatuses(company?.statuses.map((status: any) => ({
                label: `${status.title} (${status.code})`,
                value: status.code
            })))
        }
    }, [company?.id])

    const clear = () => {
        setOptions({} as Options);
        setProperties({} as SBISPresto);
    };

    const updateOptions = async (state: SBISPresto) => {
        if (! (state.secretKey || state.serviceKey)) {
            clear()
            return;
        }

        const options = await fetchOptions(company.id, BigInt(data.id), diff<SBISPresto>(initialProperties, state));

        if (undefined === options) {
            return;
        }

        if (0 === Object.keys(options).length) {
            return;
        }

        setOptions(options);
        await updateProperties({options});

        showOptionsUpdatedToast()
    };

    const updateProperties = async (updating: SBISPresto) => {
        delete errors['integrations.sbisPresto.integrationIdentifierSecret'];

        setProperties(properties => {
            const state = {...properties, ...updating};
            const keys = Object.keys(updating);

            if (! keys.some((key) => ['id', 'serviceKey', 'secretKey', 'pointId'].includes(key))) {
                return state;
            }

            updateOptions(state);

            return state;
        });
    }

    const updateIntegration: UpdateProperties = () => {
        // Костыль. Объект обновляется полностью, кроме секретного ключа и сервисного ключа
        const update = { ...properties };
        if (update.secretKey === initialProperties.secretKey) {
            delete update.secretKey;
        }

        if (update.serviceKey === initialProperties.serviceKey) {
            delete update.serviceKey;
        }

        updateField(`integrations.sbisPresto`, update)
    }

    const updateSecretToken = () => {
        if (! properties.integrationIdentifierSecret) {
            setLoadSecretToken(true);

            apiClient.branches.updateSecterToken(company.id)
              .then(async ({ data }) => {
                  await updateProperties({integrationIdentifierSecret: data.secret});
              })
              .finally(() => {
                  setLoadSecretToken(false);
              });

            return;
        }

        dialogModal({
            isOpen: false,
            title: 'Внимание!',
            text: 'Вы уверены что хотите обновить секретный ключ?',
            showCancelButton: true,
            confirmButtonText: 'Обновить',
            cancelButtonText: 'Вернуться',
        })
          .then((result) => {
              if (! result.isConfirmed) {
                  return;
              }

              setLoadSecretToken(true);
              apiClient.branches.updateSecterToken(company.id).then(async ({ data }) => {
                  await updateProperties({integrationIdentifierSecret: data.secret});
              })
          })
          .catch(() => {
          })
          .then(() => {
              setLoadSecretToken(false);
          });
    }

    useEffect(() => {
        if (! isDirty(data.integrations.sbisPresto || {}, properties)) {
            return;
        }

        updateIntegration();
    }, [JSON.stringify(properties)]);

    return (
      <Spoiler title={ 'СБИС Presto' } defaultOpen={ true }>
          {/* @ts-ignore */ }
          <Spoiler.Toggle>
              { ({ isOpen, toggleIsOpen, title }) => (
                <div className={ `text-[22px] font-semibold flex items-center justify-between cursor-pointer` }>
                    <div onClick={ () => toggleIsOpen() }>{ title }</div>
                    <div className={ 'flex items-center gap-4' }>
                        <BaseButton
                          onClick={ async () => await updateOptions(properties) }
                          className={ `bg-interactive-elem text-white py-[7px]` }
                        >Обновить данные
                        </BaseButton>
                        <ChevronIcon onClick={ () => toggleIsOpen() }
                                     className={ `${ isOpen && 'rotate-180' } duration-300` }/>
                    </div>
                </div>
              ) }
          </Spoiler.Toggle>

          <div className={ 'space-y-8' }>
              <div className='grid grid-cols-2 gap-4'>
                  <div>
                      <InputWrapper error={ errors['integrations.sbisPresto.id'] }>
                          <InputWrapper.Label>
                              ID Приложения
                          </InputWrapper.Label>
                          <Input
                            name={ 'integrations.sbisPresto.id' }
                            placeholder={ 'ID приложения' }
                            defaultValue={ data.integrations?.sbisPresto?.id || '' }
                            onBlur={ (event) => updateProperties({ id: event.target.value }) }
                          />
                      </InputWrapper>
                  </div>

                  <div>
                      <InputWrapper error={ errors['integrations.secretKey.id'] }>
                          <InputWrapper.Label>
                              Секретный ключ
                          </InputWrapper.Label>
                          <TokenInput
                            name={ 'integrations.secretKey.id' }
                            placeholder={ 'Секретный ключ' }
                            defaultValue={ data.integrations?.sbisPresto?.secretKey || '' }
                            onBlur={ (event) => updateProperties({ secretKey: event.target.value }) }
                          />
                      </InputWrapper>
                  </div>

                  <div>
                      <InputWrapper error={ errors['integrations.serviceKey.id'] }>
                          <InputWrapper.Label>
                              Сервисный ключ
                          </InputWrapper.Label>
                          <TokenInput
                            name={ 'integrations.serviceKey.id' }
                            placeholder={ 'Сервисный ключ' }
                            defaultValue={ data.integrations?.sbisPresto?.serviceKey || '' }
                            onBlur={ (event) => updateProperties({ serviceKey: event.target.value }) }
                          />
                      </InputWrapper>
                  </div>
              </div>

              <div className='grid grid-cols-2 gap-4'>
                  <SingleSelect<string>
                    value={ properties.pointId?.toString() || '' }
                    options={ options?.pointId || [] }
                    label={ 'Точка продаж' }
                    onChange={ (option) => updateProperties({ pointId: option.value }) }
                  />

                  <SingleSelect<string>
                    value={ properties.priceListId?.toString() || '' }
                    options={ options?.priceListId || [] }
                    label={ 'Прайс лист' }
                    onChange={ (option) => updateProperties({ priceListId: option.value }) }
                  />
              </div>

              <div className='grid grid-cols-2 gap-4'>
                  <SingleSelect<string>
                    value={ properties.payments?.cash || '' }
                    options={ payments || [] }
                    label={ 'Тип оплаты, Наличные' }
                    onChange={ (option) => updateProperties({ payments: { ...properties.payments, cash: option.value } }) }
                  />

                  <SingleSelect<string>
                    value={ properties.payments?.['on-delivery'] || '' }
                    options={ payments || [] }
                    label={ 'Тип оплаты, Картой при получении' }
                    onChange={ (option) => updateProperties({ payments: { ...properties.payments, ['on-delivery']: option.value } }) }
                  />

                  <SingleSelect<string>
                    value={ properties.payments?.online || '' }
                    options={ payments || [] }
                    label={ 'Тип оплаты, Online' }
                    onChange={ (option) => updateProperties({ payments: { ...properties.payments, online: option.value } }) }
                  />

                  <SingleSelect<string>
                    value={ properties.payments?.transfer || '' }
                    options={ payments || [] }
                    label={ 'Тип оплаты, Мобильный банк (Перевод)' }
                    onChange={ (option) => updateProperties({ payments: { ...properties.payments, transfer: option.value } }) }
                  />

              </div>

              <div className='grid grid-cols-2 gap-4'>
                  <div>
                      <InputWrapper>
                          <InputWrapper.Label>
                              Лимит списания баллов (0-100)
                          </InputWrapper.Label>
                          <Input
                            defaultValue={ properties.maxBonusDiscount }
                            name={ 'integrations.sbisPresto.maxBonusDiscount' }
                            placeholder={ 'Лимит списания баллов (0-100)' }
                            max={ 100 }
                            min={ 0 }
                            onBlur={ async (event) => {
                                const value = Math.max(0, Math.min(100, +event.target.value));
                                event.target.value = value.toString();
                                await updateProperties({ maxBonusDiscount: value });
                            } }
                          />
                      </InputWrapper>
                  </div>
              </div>

              <div className='grid grid-cols-2 gap-4'>
                  <SingleSelect<string>
                    value={ properties.statuses?.[10] || '' }
                    options={ statuses || [] }
                    label={ 'Статус заказа (Неподтвержденный заказ)' }
                    onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 10: option.value } }) }
                  />

                  <SingleSelect<string>
                    value={ properties.statuses?.[11] || '' }
                    options={ statuses || [] }
                    label={ 'Статус заказа (Новый, заполненный и сохраненный заказ)' }
                    onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 11: option.value } }) }
                  />

                  <SingleSelect<string>
                    value={ properties.statuses?.[21] || '' }
                    options={ statuses || [] }
                    label={ 'Статус заказа (Подтверждён рестораном)' }
                    onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 21: option.value } }) }
                  />

                  <SingleSelect<string>
                    value={ properties.statuses?.[70] || '' }
                    options={ statuses || [] }
                    label={ 'Статус заказа (Принят в работу в ресторане)' }
                    onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 70: option.value } }) }
                  />

                  <SingleSelect<string>
                    value={ properties.statuses?.[81] || '' }
                    options={ statuses || [] }
                    label={ 'Статус заказа (Собирается рестораном)' }
                    onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 81: option.value } }) }
                  />

                  <SingleSelect<string>
                    value={ properties.statuses?.[90] || '' }
                    options={ statuses || [] }
                    label={ 'Статус заказа (Передан в доставку)' }
                    onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 90: option.value } }) }
                  />

                  <SingleSelect<string>
                    value={ properties.statuses?.[91] || '' }
                    options={ statuses || [] }
                    label={ 'Статус заказа (Готов к самовывозу)' }
                    onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 91: option.value } }) }
                  />

                  <SingleSelect<string>
                    value={ properties.statuses?.[100] || '' }
                    options={ statuses || [] }
                    label={ 'Статус заказа (Доставлен)' }
                    onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 100: option.value } }) }
                  />

                  <SingleSelect<string>
                    value={ properties.statuses?.[140] || '' }
                    options={ statuses || [] }
                    label={ 'Статус заказа (Оплачен)' }
                    onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 140: option.value } }) }
                  />

                  <SingleSelect<string>
                    value={ properties.statuses?.[200] || '' }
                    options={ statuses || [] }
                    label={ 'Статус заказа (Закрыт)' }
                    onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 200: option.value } }) }
                  />

                  <SingleSelect<string>
                    value={ properties.statuses?.[220] || '' }
                    options={ statuses || [] }
                    label={ 'Статус заказа (Отменён или удалён)' }
                    onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 220: option.value } }) }
                  />
              </div>

              <div className={ 'grid grid-cols-1 mb-4' }>
                  <div className={ `flex items-end gap-5 py-3 px-4 border-gray-20 border-[1px] rounded-lg` }>
                      <div className={ 'w-full' }>
                          <InputWrapper
                            error={ errors['integrations.sbisPresto.integrationIdentifierSecret'] }
                          >
                              <InputWrapper.Label>
                                  Секретный ключ
                              </InputWrapper.Label>
                              <span
                                className={ 'pointer-events-none select-none' }>{ properties.integrationIdentifierSecret || 'Секретный ключ' }</span>
                          </InputWrapper>
                      </div>

                      <BaseButton onClick={ updateSecretToken }
                                  className={ "bg-interactive-elem text-white h-[42px] min-w-[110px]" }>
                          {
                              loadSecretToken ?
                                <div className={ 'flex flex-row items-center justify-center' }>
                                    <svg
                                      className="animate-spin h-5 text-white"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor"
                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </div> : 'Обновить'
                          }
                      </BaseButton>
                      <CopyToClipboard text={ properties.integrationIdentifierSecret }
                                       onCopy={ () => showSuccessToast({ content: "Секретный ключ скопирован", }) }>
                          <BaseButton onClick={ () => window.navigator }
                                      className={ "bg-interactive-elem text-white h-[42px]" }> Копировать </BaseButton>
                      </CopyToClipboard>
                  </div>
              </div>

              <div className='flex flex-col gap-2'>
                  {/* Инвертируем значение, ибо бек принемает send..., а лейбал: 'Не...' */ }

                  <Checkbox
                    disabled={true}
                    label='Не использовать баллы'
                    name={ 'integrations.sbisPresto.usePoints' }
                    value={ ! Boolean(+(properties?.usePoints || 0)) }
                    onChange={ (value: any) => updateProperties({ usePoints: ! value }) }
                  />
              </div>
          </div>
      </Spoiler>
    )
}
