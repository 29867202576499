import {MakeContext} from "./common";
import {useCompaniesContext} from "./Companies";
import {useBranchesContext} from "./Branches";
import {apiClient} from "../libs/api/apiClient";

const [OrdersContextWrapper, useOrdersContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.orders.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (orders: any, updateOrders: any, updateFilters: any) => ({
    orders, updateOrders, updateFilters
  })
})

export {OrdersContextWrapper, useOrdersContext}
