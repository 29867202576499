const tabs = [
  {
    label: 'Главная информация',
    tag: 'main',
    required: true,
    hasError: false,
    fields: [
      'title',
      'internalDomain',
      'externalDomain',
      'emails',
      'logo',
      'description',
      'shortDescription',
    ]
  },
  {
    label: 'Реквизиты компании',
    tag: 'requisites',
    required: true,
    hasError: false,
    fields: [
      'hideDetails',
      'companyName',
      'companyNamePlural',
      'inn',
      'kpp',
      'ogrn',
      'bik',
      'address',
      'bank',
      'correspondentAccount',
      'paymentAccount',
      'phone',
    ]
  },
  {
    label: 'Программа лояльности',
    tag: 'loyalty',
    required: false,
    hasError: false,
    fields: [
      'loyaltyProgram'
    ]
  },
  {
    label: 'Статусы заказов',
    tag: 'statuses',
    required: false,
    hasError: false,
    fields: [
      'statuses'
    ]
  },
  {
    label: 'Метки товаров (Теги)',
    tag: 'tags',
    required: false,
    hasError: false,
    fields: [
      'tags'
    ]
  },
  {
    label: 'Настройка шаблонов',
    tag: 'templates',
    required: true,
    hasError: false,
    fields: [
      'template.logo',
      'template.logoThumbnail',
      'template.favicon',
      'template.options.cartMobileOrientation',
      'template.options.orderButtonColor',
      'template.options.additionalColor',
      'template.options.mainColor',
      'template.options.theme',
      'template.code'
    ]
  }
]

export default tabs
