import {apiClient} from "../libs/api/apiClient";
import {useCompaniesContext} from "./Companies";
import {useBranchesContext} from "./Branches";
import {MakeContext} from "./common";

const [ReportOrdersContextWrapper, useReportOrdersContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: string) => apiClient.reports.orders(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (reports: any, updateReports: any, updateFilters: any) => ({
    reports, updateReports, updateFilters
  })
})

export {ReportOrdersContextWrapper, useReportOrdersContext};

const [ReportProductsContextWrapper, useReportProductsContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: string) => apiClient.reports.products(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (reports: any, updateReports: any, updateFilters: any) => ({
    reports, updateReports, updateFilters
  })
})

export {ReportProductsContextWrapper, useReportProductsContext};

const [ReportPromoCodeContextWrapper, useReportPromoCodeContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: string) => apiClient.reports.promoCodes(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (reports: any, updateReports: any, updateFilters: any) => ({
    reports, updateReports, updateFilters
  })
})

export {ReportPromoCodeContextWrapper, useReportPromoCodeContext};
