import React, {createContext, ReactElement, useContext, useEffect, useRef, useState} from "react";

export const preparePaginationQueryParams = (params: Object) => {
  return '?' + Object.keys(params)
    .filter(key => params[key] != null)
    .map(key => {
      if (key === 'order') {
        return Object.entries(params[key]).map(([orderField, orderValue]) => {
          return `order[${orderField}]=${orderValue}`
        }).join('&')
      }
      if (key === 'filter') {
        return Object.entries(params[key]).filter(([filterKey, filterValue]) => filterValue != null).map(([filterField, filterValue]) => {
          if (filterValue && filterValue instanceof Array && filterValue.length) {
            return filterValue.map((value, index) => {return `filter[${filterField}][]=${value}`}).join('&')
          }
          if (filterValue && typeof filterValue === 'object' && Object.keys(filterValue).length) {
            return Object.entries(filterValue).filter(([filterObjKey, filterObjValue]) => filterObjValue != null).map(([filterObjKey, filterObjValue]) => {
              return `filter[${filterField}][${filterObjKey}]=${filterObjValue}`
            }).join('&')
          }
          return `filter[${filterField}]=${filterValue}`
        }).join('&')
      }
      return `${key}=${encodeURIComponent(params[key])}`;
    }).join('&');
}

export function MakeContext({useEverything, makeValue}: any) : [({children}: any) => ReactElement, () => {loading : boolean}] {
  const Context = createContext({
    loading: false,
    pagination: {},
    filters: {},
    limits: {},
    ...makeValue(
      [], // all
      () => {}, // updateAll
      () => {}, // updateFilters
    )
  })

  const ContextWrapper = ({children}: any) => {
    const [loading, setLoading] = useState(true)
    const [all, setAll] = useState([]);
    const [pagination, setPagination] = useState({})
    const [filters, setFilters] = useState({})
    const [limits, setLimits] = useState({})

    const { isReady, fetchAll, updateDeps } = useEverything();
    const prevReqRef = useRef(0);

    const updateAll = (pagination: any = null, filters: any = null) => {
      if (!isReady || isReady()) {
        if (!loading) {
          setLoading(true)
        }
        const queryParams = getQueryParams(pagination, filters)
        const reqId = ++prevReqRef.current;
        fetchAll(queryParams).then(({data, meta}: any) => {
          if (prevReqRef.current !== reqId)
            return
          setLoading(false)
          setAll(data)
          if (meta) {
            setPagination(meta)
          }
        })
      }
    }

    const updateAllWithParams = (pagination: any = null, filters: any = null) => {
      setLimits(pagination ? {...pagination} : {})
      setFilters(filters ? {...filters} : {})
    }

    const getQueryParams = (pagination: any = null, filters: any = null): string => {
      const queryPaginationParams = pagination ? preparePaginationQueryParams(pagination) : ''
      const queryFilterParams = filters ? pagination ? '&'+preparePaginationQueryParams(filters).substring(1) : preparePaginationQueryParams(filters) : ''
      return queryFilterParams.length > 1 ? `${queryPaginationParams}${queryFilterParams}` : queryPaginationParams
    }

    const updateFilters = (path: any, value: any) => {
      path = path.split('.')

      let tempData = filters;
      path.forEach((key: any, index: any) => {
        if (index === path.length - 1) {
          if (tempData instanceof Object) {
            tempData[key] = value
          } else {
            tempData = {[key]: value}
          }
        } else {
          if (!(tempData[key] instanceof Object)) {
            tempData[key] = {}
          }
          if (!(key in tempData)) {
            tempData[key] = {}
          }
          tempData = tempData[key]
        }
      })
      setFilters(tempData)
    }

    useEffect(() => updateAll(limits, filters), [limits, filters, ...updateDeps])

    return (
      <Context.Provider
        value={{
          loading,
          pagination,
          filters,
          limits,
          ...makeValue(all, updateAllWithParams, updateFilters)
        }}>
        {children}
      </Context.Provider>
    )
  }

  function useThisContext() { return useContext(Context) }
  return [ContextWrapper, useThisContext]
}

