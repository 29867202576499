import {apiClient} from "../libs/api/apiClient";
import {useCompaniesContext} from "./Companies";
import {useBranchesContext} from "./Branches";
import {MakeContext} from "./common";

const [StocksContextWrapper, useStocksContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: string) => apiClient.stocks.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (stocks: any, updateStocks: any, updateFilters: any) => ({
    stocks, updateStocks, updateFilters
  })
})

export {StocksContextWrapper, useStocksContext};
