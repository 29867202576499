import {apiClient} from "../libs/api/apiClient";
import {useCompaniesContext} from "./Companies";
import {useBranchesContext} from "./Branches";
import {MakeContext} from "./common";

const [NpsQuestionContextWrapper, useNpsQuestionContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: string) => apiClient.nps.questions.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (npsQuestions: any, updateNpsQuestions: any, updateFilters: any) => ({
    npsQuestions, updateNpsQuestions, updateFilters
  })
})

export {NpsQuestionContextWrapper, useNpsQuestionContext};

const [NpsAnswerContextWrapper, useNpsAnswerContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: string) => apiClient.nps.answers.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (npsAnswers: any, updateNpsAnswers: any, updateFilters: any) => ({
    npsAnswers, updateNpsAnswers, updateFilters
  })
})

export {NpsAnswerContextWrapper, useNpsAnswerContext};


const [NpsReviewContextWrapper, useNpsReviewContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: string) => apiClient.nps.orderReviews.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (reviews: any, updateNpsReviews: any, updateFilters: any) => ({
    reviews, updateNpsReviews, updateFilters
  })
})

export {NpsReviewContextWrapper, useNpsReviewContext};

const [NpsGoodReviewContextWrapper, useNpsGoodReviewContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: string) => apiClient.nps.goodOrderReviews.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (goodReviews: any, updateNpsGoodReviews: any, updateFilters: any) => ({
    goodReviews, updateNpsGoodReviews, updateFilters
  })
})

export {NpsGoodReviewContextWrapper, useNpsGoodReviewContext};
