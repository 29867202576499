import { apiClient } from "../libs/api/apiClient";
import { useCompaniesContext } from "./Companies";
import {MakeContext} from "./common";

const [EmployeesContextWrapper, useEmployeesContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()

    return {
      isReady: () => (company),
      fetchAll: (queryParams: string) => apiClient.employees.getAll(company.id, queryParams),
      updateDeps: [company?.id]
    }
  },
  makeValue: (employees: any, updateEmployees: any, updateFilters: any) => ({
    employees, updateEmployees, updateFilters
  })
})

export {EmployeesContextWrapper, useEmployeesContext};
