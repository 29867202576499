import {apiClient} from "../libs/api/apiClient";
import {useCompaniesContext} from "./Companies";
import {useBranchesContext} from "./Branches";
import {MakeContext} from "./common";

const [LoyaltyStatusContextWrapper, useLoyaltyStatusContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.statusesLoyalty.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (statuses: any, updateStatuses: any, updateFilters: any) => ({
    statuses, updateStatuses, updateFilters
  })
})

export {LoyaltyStatusContextWrapper, useLoyaltyStatusContext};


const [LoyaltyRulesContextWrapper, useLoyaltyRulesContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.rulesLoyalty.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (rules: any, updateRules: any, updateFilters: any) => ({
    rules, updateRules, updateFilters
  })
})

export {LoyaltyRulesContextWrapper, useLoyaltyRulesContext};


const [LoyaltyTransactionsContextWrapper, useLoyaltyTransactionsContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.transactionsLoyalty.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (transactions: any, updateTransactions: any, updateFilters: any) => ({
    transactions, updateTransactions, updateFilters
  })
})
export {LoyaltyTransactionsContextWrapper, useLoyaltyTransactionsContext};
