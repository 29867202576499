import {apiClient} from "../libs/api/apiClient";
import {useCompaniesContext} from "./Companies";
import {useBranchesContext} from "./Branches";
import {MakeContext} from "./common";

const [PromoContextWrapper, usePromoContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: string) => apiClient.promo.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (promoCodes: any, updatePromoCodes: any, updateFilters: any) => ({
    promoCodes, updatePromoCodes, updateFilters
  })
})

export {PromoContextWrapper, usePromoContext};
