import {apiClient} from "../libs/api/apiClient";
import {useCompaniesContext} from "./Companies";
import {useBranchesContext} from "./Branches";
import {MakeContext} from "./common";

const [OptionsContextWrapper, useOptionsContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.options.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (options: any, updateOptions: any, updateFilters: any) => ({
    options, updateOptions, updateFilters
  })
})

export {OptionsContextWrapper, useOptionsContext};


const [OptionsGroupContextWrapper, useOptionsGroupContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.optionsGroups.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (optionsGroups: any, updateOptionsGroups: any, updateFilters: any) => ({
    optionsGroups, updateOptionsGroups, updateFilters
  })
})

export {OptionsGroupContextWrapper, useOptionsGroupContext};


const [SkuGroupContextWrapper, useSkuGroupContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.skuGroups.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (skuGroups: any, updateSkuGroups: any, updateFilters: any) => ({
    skuGroups, updateSkuGroups, updateFilters
  })
})
export {SkuGroupContextWrapper, useSkuGroupContext};
